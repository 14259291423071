// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concurs-js": () => import("./../../../src/pages/concurs.js" /* webpackChunkName: "component---src-pages-concurs-js" */),
  "component---src-pages-dps-js": () => import("./../../../src/pages/dps.js" /* webpackChunkName: "component---src-pages-dps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-salut-create-card-js": () => import("./../../../src/pages/salut/createCard.js" /* webpackChunkName: "component---src-pages-salut-create-card-js" */),
  "component---src-pages-salut-finalizer-js": () => import("./../../../src/pages/salut/finalizer.js" /* webpackChunkName: "component---src-pages-salut-finalizer-js" */),
  "component---src-pages-salut-finish-js": () => import("./../../../src/pages/salut/finish.js" /* webpackChunkName: "component---src-pages-salut-finish-js" */),
  "component---src-pages-salut-finish-phisical-js": () => import("./../../../src/pages/salut/finishPhisical.js" /* webpackChunkName: "component---src-pages-salut-finish-phisical-js" */),
  "component---src-pages-salut-index-js": () => import("./../../../src/pages/salut/index.js" /* webpackChunkName: "component---src-pages-salut-index-js" */),
  "component---src-pages-salut-loc-de-consum-js": () => import("./../../../src/pages/salut/locDeConsum.js" /* webpackChunkName: "component---src-pages-salut-loc-de-consum-js" */),
  "component---src-pages-salut-loc-de-consum-quest-js": () => import("./../../../src/pages/salut/locDeConsumQuest.js" /* webpackChunkName: "component---src-pages-salut-loc-de-consum-quest-js" */),
  "component---src-pages-salut-second-js": () => import("./../../../src/pages/salut/second.js" /* webpackChunkName: "component---src-pages-salut-second-js" */),
  "component---src-pages-salut-step-one-js": () => import("./../../../src/pages/salut/stepOne.js" /* webpackChunkName: "component---src-pages-salut-step-one-js" */),
  "component---src-pages-salut-step-three-js": () => import("./../../../src/pages/salut/stepThree.js" /* webpackChunkName: "component---src-pages-salut-step-three-js" */),
  "component---src-pages-salut-step-two-js": () => import("./../../../src/pages/salut/stepTwo.js" /* webpackChunkName: "component---src-pages-salut-step-two-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

